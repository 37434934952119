<template>
    <div class="box">
        <div class="box-header with-border text-center">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>

        </div>

        <div class="box-body text-center">
            <img :alt="page_title" class="img-fluid"
                 src="../../assets/img/notfound.gif" style="display: inline;">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'not_found',

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Страница не найдена',
        }),
    }
</script>
